import React, { useEffect } from "react";
import logo from "../img/logo.png";
import { useNavigate, useLocation } from "react-router-dom";

import { Button, ButtonGroup, Container } from "react-bootstrap";

function LogoHeader({ selectedType, setSelectedType, menuItems }) {
  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage.getItem("token")


  const handleLogout = async (e) => {
    e.preventDefault();
    localStorage.setItem('token', '');

    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("prevUrl", location.pathname);
    navigate(`/login?${currentUrl.searchParams}`);
  };

  const handleTypeClick = (index) => {
    setSelectedType(index);
  };

  const handleMenuItemClick = (link) => {
    // Navigate to the selected menu item while preserving current query parameters
    navigate(`/${link}${location.search}`);
  };

  return (
    <>
      <header className="header">
        <div className="header-middle">
          <div className="container header-middle-cont">
            <div className="toplogo">
              <a href="/">
                <img src={logo} alt="Big Sisters of BC Lower Mainland" />
              </a>
            </div>
            <div className="shop-menu social-icons">
              <ButtonGroup>
                {menuItems.map((menuItem, index) => (
                  <Button
                    key={index}
                    variant={
                      selectedType === index ? "primary" : "outline-primary"
                    }
                    onClick={() => handleMenuItemClick(menuItem.link)}
                    className="m-1"
                  >
                    {menuItem.label}
                  </Button>
                ))}

{token && (
                  <>
                    <Button
                      key="1000"
                      variant="primary"
                      onClick={handleLogout}
                      className="m-1"
                    >
                      Log Out
                    </Button>
                  </>
                )}
              </ButtonGroup>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default LogoHeader;