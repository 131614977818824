import React, { useState, useEffect } from "react";
import "./css/Auth.css"; // Assuming you have a separate CSS file for styling
import AuthLogoHeader from "./AuthLogoHeader";
import { useNavigate, useLocation } from "react-router-dom";
import Spinner from "./Spinner";
import {fetchUserData} from "./authService"

const Auth = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});
  const [prevUrl, setPrevUrl] = useState({});
  const [chair_num, setChair_num] = useState({});

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const prevUrl = searchParams.get("prevUrl");
    const chair_num = searchParams.get("chair_num");
    setPrevUrl(prevUrl);
    setChair_num(chair_num);

    const fetchData = async () => {
      const responseData = await fetchUserData();
      if (responseData) {

        localStorage.setItem("email", responseData.email);
        localStorage.setItem("family_name", responseData.family_name);
        localStorage.setItem("given_name", responseData.given_name);

        if (chair_num) {
          navigate(`${prevUrl || "/"}?chair_num=${chair_num}`);
        } else {
          navigate(prevUrl || "/");
        }
      }
    };

    fetchData();
  }, [navigate]);


  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch("https://dev.api.bigsisters.bc.ca/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: email, password: password }),
      });

      setIsLoading(false);

      console.log(response);
      if (response.ok) {
        const responseData = await response.json();
        localStorage.setItem("token", responseData.token);
        localStorage.setItem("email", responseData.email);
        localStorage.setItem("family_name", responseData.family_name);
        localStorage.setItem("given_name", responseData.given_name);
        localStorage.setItem("token_expiry_time", responseData.token_expiry_time);

        console.log("WHERE");
        console.log(chair_num);

        if (chair_num) {
          navigate(`${prevUrl || "/"}?chair_num=${chair_num}`);
        } else {
          navigate(prevUrl || "/");
        }
      } else {
        // Handle failed login
        setError({
          message:
            "Your specified username and password failed to login. Please check your details and try again.",
        });
      }
    } catch (error) {
      setError({ message: `Login failed: ${error}` });
    }
  };

  return (
    <>
      <AuthLogoHeader />
      <main>
        <section className="container stylization maincont">
          <h1 className="main-ttl">
            <span>Login</span>
          </h1>
          <div className="auth-wrap">
            <div className="auth-col">
              {error?.message && <p className="form-error">{error?.message}</p>}
              <form method="post" className="login" onSubmit={handleLogin}>
                <p>
                  <label htmlFor="username">
                    E-mail <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="username"
                    placeholder="name@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </p>
                <p>
                  <label htmlFor="password">
                    Password <span className="required">*</span>
                  </label>
                  <input
                    type="password"
                    id="password"
                    placeholder="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </p>
                <p className="auth-submit">
                  <input type="submit" value="Login" />
                </p>
              </form>
            </div>
          </div>
        </section>
        {isLoading && <Spinner />}
      </main>
    </>
  );
};

export default Auth;