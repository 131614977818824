export const allCheckins = async () => {
  try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/all-checkins`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        return false;
      }

      const responseBody = response.json();

      return responseBody;
    } catch (err) {
      return false;
    }
}


export const checkin = async (identifier, chair_num) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/checkin`, {
          method: "POST",
          body: JSON.stringify({
            barcode: identifier,
            chairNumber: chair_num
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          return false;
        }

        const responseBody = response.json();

        return responseBody;
      } catch (err) {
        return false;
      }
}


export const delete_checkin = async (identifier) => {
  try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/checkin?checkin_id=${identifier}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        return false;
      }

      const responseBody = response.json();

      return responseBody;
    } catch (err) {
      return false;
    }
}
